@import 'styles/includes.scss';

.CampaignModal {
    $root: &;

    @include media(m) {
        padding: 40px 24px !important;
    }

    &__Container {
        @extend %container;
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        width: 100%;

        @include media(m) {
            flex-direction: row;
            align-items: center;
            max-width: 926px;
        }

        @include media(xl) {
            max-width: 938px;
        }

        button {
            flex: 1 1 50%;

            #{$root}--WhiteOnBlue & {
                background-color: $colorPaleYellow;
                color: $colorDawnBlue;
            }
        }
    }

    &__Text {
        @extend %h3;
        color: $colorDawnBlue;

        #{$root}--WhiteOnBlue & {
            color: #fff;
        }
    }

    &__ModalContent {
        position: relative;
        display: flex;
        flex-direction: column;
        width: calc(100% - 16px);
        max-width: 500px;
        max-height: calc(100% - 16px);
        background-color: #fff;
        overflow-y: auto;

        @include media(m) {
            width: calc(100% - 32px);
            max-height: calc(100% - 32px);
        }
    }

    &__Share {
        position: relative;
        width: 100%;
        height: 90px;
        background-color: white;
        padding: 0 20px 110px 20px;
        text-align: center;

        @include media(s) {
            height: 110px;
            padding-top: 0;
        }

        @include media(m) {
            height: 130px;
            padding-top: 60px;
        }

        button {
            background-color: #fff;
            border: solid black 1px;
            cursor: pointer;

            &:hover {
                background-color: #fff;
            }
        }
    }

    &__IframeContainer {
        flex: 0 0 auto;
        position: relative;
        height: 100%;
        margin-top: -100px;
        overflow: hidden;

        @include media(s) {
            margin-top: -95px;
            height: 755px !important;
        }

        @include media(m) {
            height: 632px !important;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 50%;
            width: 165%;
            height: 9999px; // Prevent scrollbar
            transform: translateX(-50%);
            overflow: hidden;
        }
    }
}
