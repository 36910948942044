@import 'styles/includes.scss';

.Root {
    $root: &;

    padding: 8px 0 24px;

    @include media(m) {
        padding: 16px 24px 40px !important;
    }

    &--BlackOnYellow {
        color: $colorDawnBlue;
    }

    &__Container {
        @extend %container;

        width: 100%;
        max-width: (map-get($maxwidths, content));

        @include media(m) {
            max-width: 926px;
        }

        @include media(xl) {
            max-width: 938px;
        }
    }

    &__Title {
        @extend %h3;

        margin-bottom: 24px;
        color: $colorDawnBlue;
    }

    &__Form {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 8px;

        @include media(m) {
            flex-direction: row;
        }
    }

    &__NameContainer {
        position: absolute;
        left: -9999px;
    }

    &__Field {
        position: relative;
        flex: 1 1 auto;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 16px;
            margin: auto;
            width: 24px;
            height: 24px;
            background-image: url('#{$basepath}svg/icon-search--dawn-blue.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    &__Input {
        width: 100%;
        min-width: 0;
        height: 100%;
        min-height: 37px;
        padding-left: 48px;
        color: $colorDawnBlue;
        font-size: 1.6rem;
        font-weight: 600;
        font-family: $fontSourceSansPro;
        border: none;
        border-radius: 0;
        background: white;
        box-shadow: none;
        -webkit-appearance: none;
    }

    &__Submit {
        flex: 0 1 auto;
        min-width: 120px;

        @include media(sm) {
            min-width: 150px;
        }
    }

    &__Loader {
        margin: 19px 0;

        @include media(m) {
            margin: 35px 0;
        }
    }

    &__Message {
        margin-top: 16px;

        @include media(m) {
            margin-top: 32px;
        }
    }

    &__Result {
        margin-top: 16px;

        @include media(m) {
            margin-top: 32px;
        }
    }

    &__List {
        list-style-type: disc;
        margin-left: 20px;
    }

    &__Item {
        margin: 4px 0;

        &:first-child {
            font-weight: bold;
            list-style: none;
            margin-left: -20px;
        }
    }

    &__Share {
        display: flex;
        gap: 8px;
        margin-top: 16px;

        @include media(m) {
            justify-content: center;
            align-items: center;
        }

        #{$root}--BlackOnYellow & {
            button {
                background-color: transparent;
                border: solid $colorDawnBlue 1px;
                color: $colorDawnBlue;

                    span {
                        background-image: url('#{$basepath}svg/icon-share-blue.svg');
                    }
            }
        }

        #{$root}--WhiteOnBlue & {
            button {
                background-color: transparent;
                border: solid white 1px;
                color: white;

                span {
                    background-image: url('#{$basepath}svg/icon-share-white.svg');
                }
            }
        }
    }
}
